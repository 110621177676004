import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const AdComponent = ({ adId, style }) => {
  const adContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      googletag.cmd.push(function() { 
        googletag.display('${adId}'); 
      });
    `;
    if (adContainerRef.current) {
      adContainerRef.current.appendChild(script);
    }

    return () => {
      if (adContainerRef.current) {
        adContainerRef.current.innerHTML = '';
      }
    };
  }, [adId]);

  return (
    <div 
      id={adId} 
      ref={adContainerRef} 
      style={{ width: '100%', height: 'auto' }}
    ></div>
  );
};

AdComponent.propTypes = {
  adId: PropTypes.string.isRequired,
};

export default AdComponent;
