import React, { useContext, useEffect, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, useHistory, Redirect } from "react-router-dom";
import ErrorFallBack from "./components/errorFallBack";

import Routing from "./components/routing";
import ProtectedRoute from "./secure/protectedRoute/ProtectedRoute";
import "./style/Main.scss";

import "moment/locale/tr";

import LoginPage from "./components/loginPage";
import SignUp from "./views/signUp";
import WireCard from "./components/wireCard";
import Purchase from "./views/purchase/Purchase";
import { axiosInstance } from "./api/config/configApi";
import axios from "axios";
import ErrorPage from "./views/errorPage/ErrorPage";
import ForgotPasswordPage from "./components/forgotPasswordPage/ForgotPasswordPage";
import ResetPassword from "./components/resetPassword/ResetPassword";
import Packets from "./components/packets/Packets";

function App() {
  const history = useHistory();

  axiosInstance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      console.log("error: ", error.response?.status);
      //history.push("/errorpage");
      return Promise.reject(error);
    }
  );

  return (
    <div>
      <div>
        <div>
          <Route path="/" exact>
            <Redirect to="/dashboard/livetv" />
          </Route>
          {/*<Route path="/login" component={LoginPage} />*/}
          <Route exact path="/login">
            <Redirect to="/dashboard" />
          </Route>
          <Route path="/signup" component={SignUp} />
          <Route path="/packets" component={Packets} />
          <Route path="/purchase" component={Purchase} />
          <Route path="/errorpage" component={ErrorPage} />
          <Route path="/forgot-pw" component={ForgotPasswordPage} />
          <Route path="/forgot-password" component={ResetPassword} />
          {/* <Route path="/redirect" component={LoginRedirect} />
            <Route path="/landingpage" component={LandingPage} />
            <Route path="/select-login" component={SelectLoginPage} />
            <Route path="/forgot-password" component={ForgotPasswordPage} />
            <Route path={`/channelcontrol`} component={ChannelControl} />
            <Route path={`/channelcoverage`} component={ChannelCoverage} />
            <Route path={`/channellocking`} component={ChannelLocking} />
            <ProtectedRoute path="/profiles/:id">
              <ProfileList />
            </ProtectedRoute>
            <ProtectedRoute path="/profile/:profileCode">
              <ProfileSettings />
            </ProtectedRoute>
            <Route path="/selectprofile" component={ProfileSelection} /> */}
          <Route path="/dashboard">
            <Routing />
          </Route>
        </div>
      </div>
    </div>
  );
}

export default App;
