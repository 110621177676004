import React, { useContext, useEffect, useRef, useState } from "react";
import ChannelInfo from "../channelInfo";
import { getChannelPlayUrl } from "../../api/ltv/ltv";
import VideoPlayer from "../videoPlayer/VideoPlayer";
import { SessionContext } from "../../context/SessionContext";
import localizationStrings from "../../statics/localization";
import MyTvChannelInfo from "../myTvChannelInfo";
import { getVodPlayUrl } from "../../api/vod/vod";
/* import { getProgramPlayUrl } from "../../api/npvr/npvr"; */
import { getProgramAndNpvrThumbnail } from "../../api/common/commonApi";
import moment from "moment";
import { formatThumbnails } from "../../utils";
import { formatProgramThumbnails } from "../../utils/formatProgramThumbnails";
import { isSafari } from "react-device-detect";
import { getProgramPlayUrl } from "../../api/ltv/ltv";
import ProgramInfo from "../../components/programInfo";

const options = {
  // fill: true,
  fluid: true,
  // responsive: true,
  preload: "auto",
  controls: true,
  liveui: true,
  autoplay: true,
  controlBar: {
    pictureInPictureToggle: false
  },
  html5: {
    nativeTextTracks: isSafari,
    dash: {
      useTTML: true
    }
  }
};

const ChannelDetail = ({
  selectedChannel,
  channels,
  genres,
  selectedGenre,
  setSelectedGenre,
  searchFilter,
  setCanWatch,
  setLockChannelCode
}) => {
  const [error, setError] = useState(null);
  const [isMyTvChannel, setIsMyTvChannel] = useState(false);
  const [thumbnails, setThumbnails] = useState([]);
  const [isTimeshiftEnable, setIsTimeshiftEnable] = useState(null);
  const [showAd, setShowAd] = useState(true);
  const [isPltv, setIsPltv] = useState(false);
  const [prevueCode, setPrevueCode] = useState(null);
  const [currentChannel, setCurrentChannel] = useState(null);

  const {
    setSelectedChannel,
    userFrameConfig,
    selectedCutvProgram,
    setSelectedCutvProgram,
    isLiveSelected,
    setIsLiveSelected,
    userInfo,
    userPackage,
    playUrl,
    setPlayUrl,
    profilePin,
    programInfo
  } = useContext(SessionContext);
  const myTvChannelProgram = useRef(null);
  const ref = useRef(null);
  const channel = useRef(null);
  const livePlayUrl = useRef(null);
  const pltvPlayUrl = useRef(null);

  useEffect(() => {
    if (selectedChannel?.id) {
      getPlayUrl(selectedChannel?.id);
    }
  }, [selectedChannel]);

  const getChannelDetail = async (channelCode) => {
    livePlayUrl.current = null; //reset play url
    pltvPlayUrl.current = null;
    setIsPltv(false);

    channel.current = selectedChannel;
    setIsTimeshiftEnable(
      !(
        channel.current.tsavailable === "0" ||
        channel.current.timeshiftenable === "0"
      ) && userInfo?.usertype === "1"
    );
    if (channel.current.auth_returncode === "0") {
      if ((selectedChannel?.prevueratingid & userInfo.userlevel) > 0) {
        setError({ code: 101 });
        /* setPlayUrl(-1); */
      } else if (
        selectedChannel.islocked === "1" &&
        !selectedChannel?.canWatch
      ) {
        /* setPlayUrl(-1); */
        let err = {};
        err.code = 303;
        setError(err);
      } else {
        /* getPlayUrl(channel.current, 0); */
        // if (selectedChannel?.utcbegintime && selectedChannel?.utcendtime)
        //   getProgramThumbnail(
        //     moment(selectedChannel?.utcbegintime,"YYYY.MM.DD HH:mm").format("YYYYMMDDHHmmss"),
        //     moment(selectedChannel?.utcendtime,"YYYY.MM.DD HH:mm").format("YYYYMMDDHHmmss"),
        //     true
        //   );
      }
      if (parseInt(channel.current.advertisecontent) & 4) {
        setShowAd(true);
      } else {
        setShowAd(false);
      }
    } else if (
      channel.current.auth_returncode === "5" ||
      channel.current.auth_returncode === "-1"
    ) {
      let err = {};
      if (userInfo.usertype === "0") {
        // should be logged in.
        err.code = 401;
        err.message = "you must logged in";
      } else {
        if (selectedChannel?.isppv === "1") {
          //program is pay per view program.
          err.code = 301;
          err.message = "PPV program";
        } else {
          //user product does not contain this content
          err.code = 302;
        }
      }
      setError(err);
      /* setPlayUrl(-1); */
    }
  };

  const getPlayUrl = async (channelId, isPltv = false) => {
    try {
      let programStartTime = null;
      if (isPltv) {
        programStartTime = programInfo.startTime;
      }
      const data = await getChannelPlayUrl(channelId, programStartTime);
      let channelPlayUrl = data.data;
      setTimeout(() => {
        setPlayUrl(channelPlayUrl);
      }, 500);
      setError(null);
    } catch (error) {
      setPlayUrl(-1);
      let err = {};
      err.code = 303;
      setError(err);
    }
  };

  const scrollToTop = () => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const getMyTvChannelProgramPlayUrl = async (item) => {
    if (!!item) {
      myTvChannelProgram.current = item;
      const { data } = await getVodPlayUrl(item.programcode, item.columncode);
      if (data.returncode === "0") {
        setError(null);
        /* setPlayUrl(data.httpsplayurl); */
      } else {
        /* let _error = {
          code: data.returncode,
          message:
            data.returncode === "1"
              ? localizationStrings.error.tvSubscription
              : data.errormsg
        };
        setError(_error);
        setPlayUrl(-1); */
      }
    } else {
      /* let _error = {
        code: "no code",
        message: "there is no current program"
      };
      setError(_error);
      setPlayUrl(-1); */
    }
  };

  const getProgramUrl = async (program, contentType = null) => {
    const data = await getProgramPlayUrl(
      program.prevuecode,
      program?.channelcode
        ? program?.channelcode
        : channel.current?.channelcode,
      contentType
    );
    if (data.data.returncode === "0") {
      /* setPlayUrl(data.data.httpsplayurl); */
      if (program?.begintime && program?.endtime)
        getProgramThumbnail(
          moment(program.utcbegintime, "YYYY.MM.DD HH:mm").format(
            "YYYYMMDDHHmmss"
          ),
          moment(program.utcendtime, "YYYY.MM.DD HH:mm").format(
            "YYYYMMDDHHmmss"
          )
        );
    }
  };

  const getProgramThumbnail = async (beginTime, endTime, isLive = false) => {
    const { data } = await getProgramAndNpvrThumbnail(
      userFrameConfig.cmsid,
      channel.current.cdnchannelcode,
      beginTime,
      endTime
    );
    if (data.returncode === "0") {
      if (isLive) {
        setThumbnails(data);
      } else {
        setThumbnails(formatProgramThumbnails(data, beginTime, endTime));
      }
    } else {
      setThumbnails([]);
    }
  };

  useEffect(() => {
    if (selectedChannel) {
      /*    if (userInfo.usertype === "0") {
          // should be logged in.
          let err = {};
          err.code = 401;
          err.message = "you must logged in";
          setError(err);
          setPlayUrl(-1);
        }
        setIsMyTvChannel(true); */

      getChannelDetail(selectedChannel?.id);
      setIsMyTvChannel(false);
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (selectedCutvProgram?.prevuecode) {
      if (userInfo.usertype === "1") {
        if ((selectedCutvProgram?.ratingid & userInfo?.userlevel) > 0) {
          setError({ code: 101 });
          /*  setPlayUrl(-1); */
        } else {
          if (selectedChannel?.islocked !== "1" || selectedChannel?.canWatch) {
            setPrevueCode(selectedCutvProgram?.prevuecode);
            getProgramUrl(
              selectedCutvProgram,
              selectedCutvProgram?.isppv === "1" ? 18 : null
            );
          }
        }
      } else {
        let err = { code: 401 };
        setError(err);
        /* setPlayUrl(-1); */
      }
    }
    return () => {
      // setSelectedCutvProgram(null);
    };
  }, [selectedCutvProgram]);

  useEffect(() => {
    if (isLiveSelected) {
      //getChannelDetail(selectedChannel?.id);

      getPlayUrl(selectedChannel?.id);
      setSelectedCutvProgram(null);
    }
    return () => {
      setIsLiveSelected(false);
    };
  }, [isLiveSelected]);

  return (
    <div ref={ref} style={{ width: "100%" }}>
      {playUrl ? (
        <VideoPlayer
          key="ltv-player"
          options={options}
          isTimeshiftEnable={isTimeshiftEnable}
          src={playUrl}
          channel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
          error={error}
          myTvChannelProgram={myTvChannelProgram.current}
          cuTvProgram={selectedCutvProgram}
          setIsLiveEdge={(val) => {
            if (val === "0") {
              setIsPltv(false);

              getPlayUrl(channel.current.id);
            } else if (val === "1") {
              setIsPltv(true);

              getPlayUrl(channel.current.id, true);
            } else if (val === "2") {
              //if tstv is playing, clicked back live
              setIsPltv(false);
              setIsLiveSelected(true);
            }
          }}
          isPltv={isPltv}
          thumbnails={thumbnails}
          showAd={showAd}
          channelCdnCode={selectedChannel?.cdnchannelcode}
          channels={channels}
          genres={genres}
          selectedGenre={selectedGenre}
          setSelectedGenre={setSelectedGenre}
          searchFilter={searchFilter}
          prevueCode={prevueCode}
          setCanWatch={setCanWatch}
          setLockChannelCode={setLockChannelCode}
          setPltv={(val) => setIsPltv(val)}
        />
      ) : null}
      <ProgramInfo
        currentChannel={currentChannel}
        isTimeshiftEnable={isTimeshiftEnable}
        scrollToTop={() => scrollToTop()}
      />
    </div>
  );
};

export default ChannelDetail;
