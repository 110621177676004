import React from "react";
import RtukGeneralIcon from "../../assets/icons/svg/RtukGeneralIcon";
import RtukThirteenAgeIcon from "../../assets/icons/svg/RtukThirteenAgeIcon";
import RtukSevenAgeIcon from "../../assets/icons/svg/RtukSevenAgeIcon";
import RtukAgeIcon from "../../assets/icons/svg/RtukAgeIcon";
import localizationStrings from "../../statics/localization";

export const mainboards = [
  {
    id: 1,
    title: "Mortal Engines",
    description: "logan desc",
    duration: "122 dk",
    year: "2019",
    genre: "Dram - Suç - Gerilim",
    poster: "http://demo.atlastv.net/posters/banner/banner1.jpg"
  },
  {
    id: 2,
    title: "Terminator Genisys",
    description: "logan desc",
    duration: "122 dk",
    year: "2019",
    genre: "Dram - Suç - Gerilim",
    poster: "http://demo.atlastv.net/posters/banner/banner2.jpg"
  },
  {
    id: 4,
    title: "Iron Man",
    description: "logan desc",
    duration: "122 dk",
    year: "2019",
    genre: "Dram - Suç - Gerilim",
    poster: "http://demo.atlastv.net/posters/banner/banner3.jpg"
  },
  {
    id: 3,
    title: "Terminator: Dark Fate ",
    description: "logan desc",
    duration: "122 dk",
    year: "2019",
    genre: "Dram - Suç - Gerilim",
    poster: "http://demo.atlastv.net/posters/banner/banner4.jpg"
  }
];

export const homeList = [
  {
    labelName: "Listem",
    labelId: 111,
    vods: [
      { id: 1, isTvod: false, poster: "http://demo.atlastv.net/posters/1.png" },
      { id: 2, isTvod: true, poster: "http://demo.atlastv.net/posters/2.png" },
      { id: 3, isTvod: true, poster: "http://demo.atlastv.net/posters/3.png" },
      { id: 4, isTvod: false, poster: "http://demo.atlastv.net/posters/4.png" },
      { id: 5, isTvod: false, poster: "http://demo.atlastv.net/posters/5.png" },
      { id: 6, isTvod: false, poster: "http://demo.atlastv.net/posters/6.png" },
      { id: 7, isTvod: false, poster: "http://demo.atlastv.net/posters/7.png" },
      { id: 8, isTvod: false, poster: "http://demo.atlastv.net/posters/8.png" },
      { id: 9, isTvod: false, poster: "http://demo.atlastv.net/posters/9.png" },
      {
        id: 10,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/10.png"
      },
      {
        id: 11,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/11.png"
      },
      {
        id: 12,
        isTvod: true,
        poster: "http://demo.atlastv.net/posters/12.png"
      },
      {
        id: 13,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/13.png"
      },
      {
        id: 14,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/14.png"
      },
      {
        id: 15,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/15.png"
      },
      {
        id: 16,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/16.png"
      }
    ]
  },
  {
    labelName: "Öne Çıkanlar",
    labelId: 112,
    vods: [
      {
        id: 17,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/17.png"
      },
      {
        id: 18,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/18.png"
      },
      {
        id: 19,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/19.png"
      },
      {
        id: 20,
        isTvod: true,
        poster: "http://demo.atlastv.net/posters/20.png"
      },
      {
        id: 21,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/21.png"
      },
      {
        id: 22,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/22.png"
      },
      {
        id: 16,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/16.png"
      },
      { id: 5, isTvod: false, poster: "http://demo.atlastv.net/posters/5.png" },
      { id: 1, isTvod: false, poster: "http://demo.atlastv.net/posters/1.png" },
      { id: 3, isTvod: true, poster: "http://demo.atlastv.net/posters/3.png" },
      { id: 7, isTvod: false, poster: "http://demo.atlastv.net/posters/7.png" },
      { id: 4, isTvod: false, poster: "http://demo.atlastv.net/posters/4.png" },
      {
        id: 15,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/15.png"
      },
      { id: 6, isTvod: false, poster: "http://demo.atlastv.net/posters/6.png" },
      { id: 2, isTvod: true, poster: "http://demo.atlastv.net/posters/2.png" },
      { id: 8, isTvod: false, poster: "http://demo.atlastv.net/posters/8.png" }
    ]
  },
  {
    labelName: "En Son Eklenenler",
    labelId: 113,
    vods: [
      { id: 9, isTvod: false, poster: "http://demo.atlastv.net/posters/9.png" },
      {
        id: 10,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/10.png"
      },
      {
        id: 11,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/11.png"
      },
      {
        id: 12,
        isTvod: true,
        poster: "http://demo.atlastv.net/posters/12.png"
      },
      {
        id: 13,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/13.png"
      },
      {
        id: 14,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/14.png"
      },
      {
        id: 15,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/15.png"
      },
      {
        id: 16,
        isTvod: false,
        poster: "http://demo.atlastv.net/posters/16.png"
      },
      { id: 1, isTvod: false, poster: "http://demo.atlastv.net/posters/1.png" },
      { id: 2, isTvod: true, poster: "http://demo.atlastv.net/posters/2.png" },
      { id: 3, isTvod: true, poster: "http://demo.atlastv.net/posters/3.png" },
      { id: 4, isTvod: false, poster: "http://demo.atlastv.net/posters/4.png" },
      { id: 5, isTvod: false, poster: "http://demo.atlastv.net/posters/5.png" },
      { id: 6, isTvod: false, poster: "http://demo.atlastv.net/posters/6.png" },
      { id: 7, isTvod: false, poster: "http://demo.atlastv.net/posters/7.png" },
      { id: 8, isTvod: false, poster: "http://demo.atlastv.net/posters/8.png" }
    ]
  }
];

export const vods = [
  {
    id: 1,
    title: "Alpha",
    originalTitle: "Alpha",
    year: "2018",
    duration: "124",
    genres: ["Fantastik", "Bilim Kurgu", "Aksiyon"],
    director: ["Albert Hughes"],
    actors: ["Kodi Smith", "Natassia Malthe", "Leonor Varela"],
    synopsis:
      "In the prehistoric past, a young man struggles to return home after being separated from his tribe during a buffalo hunt. He finds a similarly lost wolf companion and starts a friendship that would change humanity.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/1.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/1.png"
  },
  {
    id: 2,
    title: "Amelie",
    originalTitle: "Le fabuleux destin d'Amélie Poulain",
    year: "2001",
    duration: "129",
    genres: ["Komedi", "Romantik"],
    director: ["Jean-Pierre Jeunet"],
    actors: ["Audrey Tautou", "Mathieu Kassovitz", "Rufus"],
    synopsis:
      "Amélie is an innocent and naive girl in Paris with her own sense of justice. She decides to help those around her and, along the way, discovers love.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/2.png",
    type: "MOVIE",
    isTvod: true,
    rentPrice: 6,
    estPrice: 16,
    rentDuration: 2,
    estDuration: 20,
    poster: "http://demo.atlastv.net/posters/2.png"
  },
  {
    id: 3,
    title: "Tam Gaz",
    originalTitle: "Baby Le Chauffeur",
    year: "2017",
    duration: "115",
    genres: ["Aksiyon", "Gerilim", "Suç"],
    director: ["Edgar Wright"],
    actors: ["Ansel Elgort", "Lily James", "Jon Hamm"],
    synopsis:
      "Tam Gaz, yönetmenliğini ve senaristliğini Edgar Wright tarafından gerçekleştirilmiş 2017 çıkışlı aksiyon, komedi ve suç filmidir. Filmin başrollerinde Ansel Elgort, Kevin Spacey, Lily James, Eiza González, Jon Hamm, Jamie Foxx ve Jon Bernthal yer almaktadır.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/3.png",
    type: "MOVIE",
    isTvod: true,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/3.png"
  },
  {
    id: 4,
    title: "Bıçak Sırtı",
    originalTitle: "Blade Runner",
    year: "1982",
    duration: "117",
    genres: ["Fantastik", "Bilim Kurgu", "Aksiyon"],
    director: ["Ridley Scott"],
    actors: ["Harrison Ford", "Sean Young", "Rutger Hauer"],
    synopsis:
      "Bıçak Sırtı, Ridley Scott tarafından yönetilen 1982 tarihli ABD yapımı bilimkurgu filmidir. Başrollerde Harrison Ford, Rutger Hauer, ve Sean Young yer almıştır. Philip K. Dick'in Android'ler Elektrikli Koyun Düşler mi? adlı romanını temel alan senaryoyu Hampton Fancher ve David Peoples yazmıştır.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/1.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/4.png"
  },
  {
    id: 5,
    title: "Parfüm",
    originalTitle: "Perfume",
    year: "2018",
    duration: null,
    genres: ["Fantastik", "Bilim Kurgu", "Aksiyon"],
    director: ["Albert Hughes"],
    actors: ["Kodi Smith", "Natassia Malthe", "Leonor Varela"],
    synopsis:
      "Perfume, 14 Kasım 2018'de yayınlanan ZDFneo için üretilen bir Alman televizyon dizisidir. Dizi, Patrick Süskind'in aynı adlı romanından ve 2006 yılında Tom Tykwer'ın Parfüm: Bir Katilin Hikayesi'nden esinlenerek hazırlandı. modern günde.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/2.png",
    type: "SERIES",
    seasonNumber: 6,
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/5.png"
  },
  {
    id: 6,
    title: "Dunkirk",
    originalTitle: "Dunkirk",
    year: "2017",
    duration: "106",
    genres: ["Savaş", "Aksiyon", "Tarih"],
    director: ["Christopher Nolan"],
    actors: ["Harry Styles", "Tom Hardy", "Fionn Whitehead"],
    synopsis:
      "Dunkirk, Christopher Nolan'ın yazdığı ve yönettiği 2017 çıkışlı savaş filmi. Filmin yapımcılığını da Christopher Nolan ve eşi Emma Thomas birlikte üstlenmiştir. Film, II. Dünya Savaşı sırasında İngiliz ordusunun Fransa'nın Dunkerque kıyılarında 400 bin askerle sıkışması ve bu ordunun, deniz desteğiyle Dunkerque'ten tahliye edilmesini konu almaktadır.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/3.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/6.png"
  },
  {
    id: 7,
    title: "Karakomik Filmler",
    originalTitle: "Karakomik Filmler",
    year: "2019",
    duration: "123",
    genres: ["Komedi", "Dram"],
    director: ["Cem Yılmaz"],
    actors: ["Cem Yılmaz", "Zafer Algöz", "Can Yılmaz"],
    synopsis:
      "Karakomik Filmler, Kaçamak ve 2 Arada adındaki iki farklı filmden oluşuyor. Kaçamak'ta, birlikte tatile çıkan 4 arkadaşın başlarına gelen maceralar konu edilirken, tüm hikayenin arabalı vapurda geçtiği 2 Arada filminde, Ayzek adında bir adamın yaşamına odaklanılıyor.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/1.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/7.png"
  },
  {
    id: 8,
    title: "Joker",
    originalTitle: "Joker",
    year: "2019",
    duration: "124",
    genres: ["Suç", "Dram", "Aksiyon"],
    director: ["Todd Phillips"],
    actors: ["Joaquin Phoenix", "Robert De Niro", "Zazie Beetz"],
    synopsis:
      "Joker, aynı isimdeki DC Comics karakterine dayanan, Todd Phillips'in yönettiği, başrollerini Joaquin Phoenix, Robert De Niro, Frances Conroy, Brett Cullen ve Zazie Beetz'ın paylaştığı Amerikan psikolojik gerilim filmidir. 76. Venedik Uluslararası Film Festivali'nde Altın Aslan ödülünü kazanmıştır.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/1.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/8.png"
  },
  {
    id: 9,
    title: "The Archer",
    originalTitle: "The Archer",
    year: "2017",
    duration: "86",
    genres: ["Suç", "Dram", "Gerilim"],
    director: ["Valerie Weiss"],
    actors: ["Bailey Noble", "Jeanine Mason", "Micheal Terry"],
    synopsis:
      "Lauren Pierce has just become the high-school Tri-State Archery Champion. After the competition, Lauren and her teammate Emily return to their hotel room for a night of irresponsible celebratory drinking that grows into more. When interrupted by Emily's abusive boyfriend, Lauren snaps and brutally beats Daniel.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/2.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/9.png"
  },
  {
    id: 10,
    title: "Titanic",
    originalTitle: "Titanic",
    year: "1997",
    duration: "190",
    genres: ["Roamntik", "Dram", "Aksiyon"],
    director: ["James Cameron"],
    actors: ["Leonardo Di Caprio", "Kate Winslet", "Billy Zane"],
    synopsis:
      "1997 yılı Amerikan yapımı epik, felaket, ve romantizm temalı sinama filmi. RMS Titanic'in batışı üzerine kurgulanan filmin başrollerini, geminin felaketle sonuçlanan ilk seyahati sırasında birbirine aşık olan farklı toplumsal sınıflara mensup iki genci canlandıran Leonardo DiCaprio ve Kate Winslet paylaşmaktadır.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/3.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/10.png"
  },
  {
    id: 11,
    title: "Hayat",
    originalTitle: "Life",
    year: "2017",
    duration: "110",
    genres: ["Bilim Kurgu", "Korku", "Aksiyon"],
    director: ["Daniel Espinosa"],
    actors: ["Jake Gyllenhaal", "Ryan Reynolds", "Rebecca Ferguson"],
    synopsis:
      "A team of scientists aboard the International Space Station discover a rapidly evolving life form that caused extinction on Mars and now threatens all life on Earth.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/1.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/11.png"
  },
  {
    id: 12,
    title: "Logan",
    originalTitle: "Logan",
    year: "2017",
    duration: "141",
    genres: ["Bilim Kurgu", "Dram", "Aksiyon"],
    director: ["James Mangold"],
    actors: ["Hugh Jackman", "Dafne Keen", "Patrick Stewart"],
    synopsis:
      "Logan: Wolverine, James Mangold tarafından yönetilen ve Marvel Comics'in kurgusal karakteri Wolverine'den uyarlanan 2017 yapımı Amerikan süper kahraman filmi. X-Men film serisinin onuncu ve Wolverine karakterinin üçüncü ve son filmi olarak tasarlandı.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/2.png",
    type: "MOVIE",
    isTvod: true,
    rentPrice: 4,
    estPrice: 10,
    rentDuration: 2,
    estDuration: 10,
    poster: "http://demo.atlastv.net/posters/12.png"
  },
  {
    id: 13,
    title: "Kazablanka",
    originalTitle: "Casablanca",
    year: "1942",
    duration: "102",
    genres: ["Romantik", "Savaş", "Tarih"],
    director: ["Micheal Curtiz"],
    actors: ["Humphrey Bogart", "Ingrid Bergman", "Paul Henreid"],
    synopsis:
      "Kazablanka, yönetmenliğini Michael Curtiz'in üstlendiği Hollywood klasikleri arasında özel bir yere sahip film. Casablanca filminin ilk gösterimi, 1942 yılının 23 Kasım günü New York'ta yapıldı.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/3.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/13.png"
  },
  {
    id: 14,
    title: "Hacker",
    originalTitle: "Hacker",
    year: "2016",
    duration: "95",
    genres: ["Suç", "Gerilim", "Aksiyon"],
    director: ["Akan Satayev"],
    actors: ["Lorraine Nicholson", "Callan McAuliffe", "Daniel Eric"],
    synopsis:
      "Hacker '2016, Akan Satayev tarafından yönetilen, Toronto, Hong Kong, New York ve Bangkok'ta bir çevrimiçi suç grubuna ve karaborsa bayilerine katılan bir grup genç hacker hakkında yönetilen 2016 suç gerilimidir. Oyuncular Callan McAuliffe, Lorraine Nicholson, Daniel Eric Gold ve Clifton Collins Jr'den oluşuyordu.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/3.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/14.png"
  },
  {
    id: 15,
    title: "Avengers: Endgame",
    originalTitle: "Avengers: Endgame",
    year: "2019",
    duration: "182",
    genres: ["Bilim Kurgu", "Aksiyon"],
    director: ["Joe Russo", "Anthony Russo"],
    actors: ["Chris Evans", "Chris Hemsworth", "Robert Downey Jr."],
    synopsis:
      "Avengers: Endgame, Marvel Comics'in İntikamcılar adlı süper kahraman grubundan uyarlanan ve Marvel Studios tarafından yapımı üstlenilen ABD süper kahraman filmi. 2018 yapımı Avengers: Sonsuzluk Savaşı filminin devamı niteliğinde olup yirmi ikinci Marvel Sinematik Evreni filmidir.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/2.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/15.png"
  },

  {
    id: 16,
    title: "After",
    originalTitle: "After",
    year: "2019",
    duration: "105",
    genres: ["Romantik", "Dram"],
    director: ["Jenny Gage"],
    actors: ["Hero Fiennes", "Josephine Langford", "Selma Blair"],
    synopsis:
      "After, Jenny Gage tarafından yönetilen, Anna Todd'un aynı adlı 2014 yeni yetişkin kurgu romanına dayanan ve ilk olarak Wattpad'de bir hayran kurgu olarak yayınlanan 2019 Amerikan romantik drama filmi. Senaryo Susan McMartin, Tamara Chestna ve Jenny Gage tarafından yazılmıştır.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/1.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/16.png"
  },
  {
    id: 17,
    title: "Sons of Anarchy",
    originalTitle: "Sons of Anarchy",
    year: "2008",
    duration: null,
    genres: ["Suç", "Aksiyon"],
    director: ["Jenny Gage"],
    actors: ["Charlie Hunnam", "Katey Sagal", "Kurt Sutter"],
    synopsis:
      "Sons of Anarchy, Amerikan yapımı, FX kanalında yayınlanan suç ve drama içerikli bir tv dizisidir. Yapımcılığını Kurt Sutter yapmaktadır. Aynı zamanda bazı sahnelerinde de Otto Delaney olarak rol almaktadır. Dizi toplam 7 sezon sürmüştür ve her sezonda 13 bölüm bulunmaktadır",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/1.png",
    type: "SERIES",
    seasonNumber: 6,
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/17.png"
  },
  {
    id: 18,
    title: "Yıldız Savaşları",
    originalTitle: "Star Wars",
    year: "1999",
    duration: "136",
    genres: ["Bilim Kurgu", "Aksiyon", "Savaş"],
    director: ["George Lucas"],
    actors: ["Natalie Portman", "Keira Knightley", "Jake Lloyd"],
    synopsis:
      "Yıldız Savaşları: Bölüm I - Gizli Tehlike, Star Wars serisine ait 1999 yılı yapımı bir bilimkurgu filmidir. George Lucas tarafından yazılmış ve yönetilmiştir. Yapım sırasına göre serinin dördüncü filmi, hikâyenin kronolojik sıralamasına göre ilk filmidir. Film 19 Mayıs 1999 tarihinde vizyona çıkmıştır",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/2.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/18.png"
  },
  {
    id: 19,
    title: "Uzay Yolu",
    originalTitle: "Star Trek",
    year: "1966",
    duration: null,
    genres: ["Bilim Kurgu"],
    director: ["Jenny Gage"],
    actors: ["William Shatner", "Leonard Nimoy", "DeForest Kelley"],
    synopsis:
      "Uzay Yolu, Gene Roddenberry tarafından yapılmış, 8 Eylül 1966'dan 3 Haziran 1969'a kadar yayınlanmıştır. 80 bölüm hazırlanmış bunlardan 79'u gösterilmiştir. Üç sezon kadar kısa süren bir yayın hayatından sonra sendikasyona geçmiş ve orada kendine geniş bir hayran kitlesi edinmiştir.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/3.png",
    type: "SERIES",
    seasonNumber: 6,
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/19.png"
  },
  {
    id: 20,
    title: "Bohemian Rhapsody",
    originalTitle: "Bohemian Rhapsody",
    year: "2018",
    duration: "133",
    genres: ["Müzikal", "Dram"],
    director: ["Brayn Singer"],
    actors: ["Rami Malek", "Gwilym Lee", "Joseph Mazzello"],
    synopsis:
      "Bohemian Rhapsody, yönetmenliğini Bryan Singer'ın yaptığı ve Freddie Mercury'nin hayatının anlatıldığı 2018 ABD çıkışlı biyografik sinema filmi. Film 2 Kasım 2018'de Amerika Birleşik Devletleri'nde vizyona girdi",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/3.png",
    type: "MOVIE",
    isTvod: true,
    rentPrice: 10,
    estPrice: 15,
    rentDuration: 3,
    estDuration: 30,
    poster: "http://demo.atlastv.net/posters/20.png"
  },
  {
    id: 21,
    title: "Takip",
    originalTitle: "The Rover",
    year: "2014",
    duration: "103",
    genres: ["Dram", "Yol Filmi"],
    director: ["David Michod"],
    actors: ["Robert Pattinson", "Guy Pearce", "Scoot McNairy"],
    synopsis:
      "Rover, David Michôd tarafından yazılan ve yönetilen ve Michôd ve Joel Edgerton'un bir hikayesine dayanan 2014 Avustralya distopik Batı drama filmi. Küresel ekonomik çöküşten on yıl sonra Avustralya taşralarında yer alan çağdaş bir batı.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/2.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/21.png"
  },
  {
    id: 22,
    title: "Cehennem Gecesi",
    originalTitle: "Burn",
    year: "2019",
    duration: "88",
    genres: ["Aksiyon", "Gerilim", "Suç"],
    director: ["Mike Gan"],
    actors: ["Tilda Coblam", "Josh Hutcherson", "Suki Waterhold"],
    synopsis:
      "Burn, Mike Gan tarafından yazılan ve yönetilen bir 2019 Amerikan gerilim filmidir. Tilda Cobham-Hervey, Suki Waterhouse, Harry Shum Jr., Shiloh Fernandez ve Josh Hutcherson rol alıyor. 23 Ağustos 2019'da Momentum Pictures tarafından yayınlandı.",
    posterMobile: "",
    posterWeb: "http://demo.atlastv.net/posters/voddetail/1.png",
    type: "MOVIE",
    isTvod: false,
    rentPrice: null,
    estPrice: null,
    poster: "http://demo.atlastv.net/posters/22.png"
  }
];

export const seasons = [
  { id: 1, title: `1. ${localizationStrings.season}` },
  { id: 2, title: `2. ${localizationStrings.season}` },
  { id: 3, title: `3. ${localizationStrings.season}` },
  { id: 4, title: `4. ${localizationStrings.season}` },
  { id: 5, title: `5. ${localizationStrings.season}` },
  { id: 6, title: `6. ${localizationStrings.season}` }
];

export const episodes = [
  { episodeNo: 1, id: 1, seasonId: 1, title: "Pilot", progress: null },
  { episodeNo: 2, id: 2, seasonId: 1, title: "Seeds", progress: null },
  { episodeNo: 3, id: 3, seasonId: 1, title: "Fun Town", progress: null },
  { episodeNo: 4, id: 4, seasonId: 1, title: "Patch Over", progress: null },
  { episodeNo: 5, id: 5, seasonId: 1, title: "Giving Back", progress: null },
  { episodeNo: 1, id: 6, seasonId: 2, title: "AK 51", progress: null },
  { episodeNo: 2, id: 7, seasonId: 2, title: "Old Bones", progress: null },
  { episodeNo: 3, id: 8, seasonId: 2, title: "The Pull", progress: null },
  { episodeNo: 4, id: 9, seasonId: 2, title: "Hell Followed", progress: null },
  { episodeNo: 5, id: 10, seasonId: 2, title: "Better Half", progress: null },
  { episodeNo: 6, id: 11, seasonId: 2, title: "Capybara", progress: null },
  {
    episodeNo: 7,
    id: 12,
    seasonId: 2,
    title: "The Sleep of Babies",
    progress: null
  },
  { episodeNo: 1, id: 13, seasonId: 3, title: "The Revelator", progress: null },
  { episodeNo: 2, id: 14, seasonId: 3, title: "The Back", progress: null },
  {
    episodeNo: 3,
    id: 15,
    seasonId: 3,
    title: "Follow The Man",
    progress: null
  },
  { episodeNo: 4, id: 16, seasonId: 3, title: "Innocent", progress: null },
  { episodeNo: 1, id: 17, seasonId: 4, title: "Future", progress: null },
  { episodeNo: 2, id: 18, seasonId: 4, title: "Past", progress: null },
  { episodeNo: 3, id: 19, seasonId: 4, title: "Calculated", progress: null },
  { episodeNo: 4, id: 20, seasonId: 4, title: "The Big", progress: null },
  { episodeNo: 5, id: 21, seasonId: 4, title: "Half", progress: null },
  { episodeNo: 1, id: 22, seasonId: 5, title: "The Black Cat", progress: null },
  {
    episodeNo: 2,
    id: 23,
    seasonId: 5,
    title: "Beginning of The Journey",
    progress: null
  },
  { episodeNo: 3, id: 24, seasonId: 5, title: "Back in Black", progress: null },
  { episodeNo: 4, id: 25, seasonId: 5, title: "TNT", progress: null },
  {
    episodeNo: 5,
    id: 26,
    seasonId: 5,
    title: "Who Walks Alone?",
    progress: null
  },
  { episodeNo: 6, id: 27, seasonId: 5, title: "Paschandale", progress: null },
  {
    episodeNo: 7,
    id: 28,
    seasonId: 5,
    title: "Fear of The Dark",
    progress: null
  },
  {
    episodeNo: 1,
    id: 29,
    seasonId: 6,
    title: "Highway to Hell",
    progress: null
  },
  { episodeNo: 2, id: 30, seasonId: 6, title: "You Did It", progress: null },
  { episodeNo: 3, id: 31, seasonId: 6, title: "The Trooper", progress: null },
  {
    episodeNo: 4,
    id: 32,
    seasonId: 6,
    title: "Run to The Hills",
    progress: null
  },
  { episodeNo: 5, id: 33, seasonId: 6, title: "Wasted", progress: null }
];

export const programs_ = [
  {
    id: 1,
    name: "Blindspot",
    startTime: "12:00",
    endTime: "13:30",
    rating: "Genel",
    description:
      "Premise. Blindspot focuses on a mysterious tattooed woman who is found naked inside a travel bag in Times Square by the FBI. ... They discover that her tattoos contain clues to crimes they will have to solve."
  },
  {
    id: 2,
    name: "Suç ve Ceza",
    startTime: "13:30",
    endTime: "14:30",
    rating: "+18",
    description:
      "he novel is often cited as one of the supreme achievements in literature. Crime and Punishment focuses on the mental anguish and moral dilemmas of Rodion Raskolnikov, an impoverished ex-student in Saint Petersburg who formulates a plan to kill an unscrupulous pawnbroker for her money"
  },
  {
    id: 6,
    name: "Bilinmeyene Yolculuk",
    startTime: "18:00",
    endTime: "19:15",
    rating: "Genel",
    descripiton:
      "Ünlü arkeolog Josh Gates dünyanın ücra yerlerini dolaşarak insan yapımı kayıp objeleri ve bilinmeyeni yeniden keşfetmeye çalışıyor."
  },
  {
    id: 7,
    name: "Büyük Hayvan Ameliyatları",
    startTime: "19:15",
    endTime: "20:30",
    rating: "Genel",
    descripiton:
      "Bu iddialı veteriner bilim dizisi sizi büyük hayvan muayenehanesinin dramatik – ve çoğu zaman tehlikeli – dünyasına götürüyor. Uganda dağlarından Sierra Leone yağmur ormanlarına Dünya genelinde filme alınan bu program, gezegenin en simgesel hayvanları ölüm-kalım ameliyatları geçirirken bizi işin tam ortasına taşıyor."
  },
  {
    id: 8,
    name: "Diriliş Ertuğrul",
    startTime: "20:30",
    endTime: "22:45",
    rating: "Genel",
    descripiton:
      "Moğollarla zorlu bir mücadele veren Ertuğrul Bey, kutlu davasının peşinden gidebilmek için kardeşlerinden ayrılmak zorunda kalmıştı. Ertuğrul Bey’e inanan Kayılar, bu ayrılığın ardından yollara düştüler."
  },
  {
    id: 9,
    name: "Sadakatsiz",
    startTime: "22:45",
    endTime: "00:45",
    rating: "+18",
    descripiton:
      "Başrollerinde Cansu Dere ve Caner Cindoruk'un olduğu, BBC Studios’un Doctor Foster isimli dizisinin Türkiye uyarlaması olan, yapımcılığını Med Yapım’ın üstlendiği Kanal D’nin yeni dizisi, psikolojik gerilim türünde olan Sadakatsiz, kocasının onu aldattığını düşünen bir kadının hikayesini ele alıyor."
  }
];

export const programs = [
  {
    id: 1,
    prevuename: "Blindspot",
    startTime: "12:00",
    endTime: "13:30",
    rating: "Genel",
    description:
      "Premise. Blindspot focuses on a mysterious tattooed woman who is found naked inside a travel bag in Times Square by the FBI. ... They discover that her tattoos contain clues to crimes they will have to solve."
  },
  {
    id: 2,
    prevuename: "Suç ve Ceza",
    startTime: "13:30",
    endTime: "14:30",
    rating: "+18",
    description:
      "he novel is often cited as one of the supreme achievements in literature. Crime and Punishment focuses on the mental anguish and moral dilemmas of Rodion Raskolnikov, an impoverished ex-student in Saint Petersburg who formulates a plan to kill an unscrupulous pawnbroker for her money"
  },
  {
    id: 3,
    prevuename: "Ana Haber",
    startTime: "14:30",
    endTime: "15:30",
    rating: "Genel",
    description:
      "Siyasetten sıcak olaylara, ekonomiden sağlığa, her konuda hızlı, güvenilir ve objektif habercilik ilkeleriyle Türkiye’nin en çok izlenen haber bültenlerinden biri olan Star Ana Haber seyircisi ile her gün saat 19.00’da buluşuyor!"
  },
  {
    id: 4,
    prevuename: "Mars",
    startTime: "15:30",
    endTime: "16:00",
    rating: "Genel",
    description:
      "2037 yılında, Mars ve Dünya'daki herkes yaşanan bir trajedinin ardından görevi sorgulamaktadırlar. Devam edip etmeme kararının alınması gerekmektedir. Günümüzde ise SpaceX insanların kızıl gezegene ulaşmasına yardımcı olacak roket teknolojisi üzerinde çalışmaktadır."
  },
  {
    id: 5,
    prevuename: "Roma Binaları",
    startTime: "16:00",
    endTime: "18:00",
    rating: "+18",
    description:
      "he Roman basilica was a large public building where business or legal matters could be transacted. They were normally where the magistrates held court, and used for other official ceremonies, having many of the functions of the modern town hall."
  },
  {
    id: 6,
    prevuename: "Bilinmeyene Yolculuk",
    startTime: "18:00",
    endTime: "19:15",
    rating: "Genel",
    descripiton:
      "Ünlü arkeolog Josh Gates dünyanın ücra yerlerini dolaşarak insan yapımı kayıp objeleri ve bilinmeyeni yeniden keşfetmeye çalışıyor."
  },
  {
    id: 7,
    prevuename: "Büyük Hayvan Ameliyatları",
    startTime: "19:15",
    endTime: "20:30",
    rating: "Genel",
    descripiton:
      "Bu iddialı veteriner bilim dizisi sizi büyük hayvan muayenehanesinin dramatik – ve çoğu zaman tehlikeli – dünyasına götürüyor. Uganda dağlarından Sierra Leone yağmur ormanlarına Dünya genelinde filme alınan bu program, gezegenin en simgesel hayvanları ölüm-kalım ameliyatları geçirirken bizi işin tam ortasına taşıyor."
  },
  {
    id: 8,
    prevuename: "Diriliş Ertuğrul",
    startTime: "20:30",
    endTime: "22:45",
    rating: "Genel",
    descripiton:
      "Moğollarla zorlu bir mücadele veren Ertuğrul Bey, kutlu davasının peşinden gidebilmek için kardeşlerinden ayrılmak zorunda kalmıştı. Ertuğrul Bey’e inanan Kayılar, bu ayrılığın ardından yollara düştüler."
  },
  {
    id: 9,
    prevuename: "Sadakatsiz",
    startTime: "22:45",
    endTime: "00:45",
    rating: "+18",
    descripiton:
      "Başrollerinde Cansu Dere ve Caner Cindoruk'un olduğu, BBC Studios’un Doctor Foster isimli dizisinin Türkiye uyarlaması olan, yapımcılığını Med Yapım’ın üstlendiği Kanal D’nin yeni dizisi, psikolojik gerilim türünde olan Sadakatsiz, kocasının onu aldattığını düşünen bir kadının hikayesini ele alıyor."
  },
  {
    id: 10,
    prevuename: "Sadakatsiz",
    startTime: "22:45",
    endTime: "00:45",
    rating: "+18",
    descripiton:
      "Başrollerinde Cansu Dere ve Caner Cindoruk'un olduğu, BBC Studios’un Doctor Foster isimli dizisinin Türkiye uyarlaması olan, yapımcılığını Med Yapım’ın üstlendiği Kanal D’nin yeni dizisi, psikolojik gerilim türünde olan Sadakatsiz, kocasının onu aldattığını düşünen bir kadının hikayesini ele alıyor."
  },
  {
    id: 11,
    prevuename: "Sadakatsiz",
    startTime: "22:45",
    endTime: "00:45",
    rating: "+18",
    descripiton:
      "Başrollerinde Cansu Dere ve Caner Cindoruk'un olduğu, BBC Studios’un Doctor Foster isimli dizisinin Türkiye uyarlaması olan, yapımcılığını Med Yapım’ın üstlendiği Kanal D’nin yeni dizisi, psikolojik gerilim türünde olan Sadakatsiz, kocasının onu aldattığını düşünen bir kadının hikayesini ele alıyor."
  },
  {
    id: 12,
    prevuename: "Sadakatsiz",
    startTime: "22:45",
    endTime: "00:45",
    rating: "+18",
    descripiton:
      "Başrollerinde Cansu Dere ve Caner Cindoruk'un olduğu, BBC Studios’un Doctor Foster isimli dizisinin Türkiye uyarlaması olan, yapımcılığını Med Yapım’ın üstlendiği Kanal D’nin yeni dizisi, psikolojik gerilim türünde olan Sadakatsiz, kocasının onu aldattığını düşünen bir kadının hikayesini ele alıyor."
  },
  {
    id: 13,
    prevuename: "Sadakatsiz",
    startTime: "22:45",
    endTime: "00:45",
    rating: "+18",
    descripiton:
      "Başrollerinde Cansu Dere ve Caner Cindoruk'un olduğu, BBC Studios’un Doctor Foster isimli dizisinin Türkiye uyarlaması olan, yapımcılığını Med Yapım’ın üstlendiği Kanal D’nin yeni dizisi, psikolojik gerilim türünde olan Sadakatsiz, kocasının onu aldattığını düşünen bir kadının hikayesini ele alıyor."
  },
  {
    id: 14,
    prevuename: "Sadakatsiz",
    startTime: "22:45",
    endTime: "00:45",
    rating: "+18",
    descripiton:
      "Başrollerinde Cansu Dere ve Caner Cindoruk'un olduğu, BBC Studios’un Doctor Foster isimli dizisinin Türkiye uyarlaması olan, yapımcılığını Med Yapım’ın üstlendiği Kanal D’nin yeni dizisi, psikolojik gerilim türünde olan Sadakatsiz, kocasının onu aldattığını düşünen bir kadının hikayesini ele alıyor."
  }
];

export const liveChannels = [
  {
    id: 1,
    channelName: "FX",
    channelLogo: "http://demo.atlastv.net/posters/channels/1.png",
    currentProgramName: "Blindspot",
    currentProgramProgress: 22,
    programId: 1,
    genreIDs: [0, 10, 7, 8, 5, 4, 1, 3, 2, 6],
    playUrl:
      "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8",
    programs: programs_
  },
  {
    id: 2,
    channelName: "FOX CRIME",
    channelLogo: "http://demo.atlastv.net/posters/channels/2.png",
    currentProgramName: "Suç ve Ceza",
    currentProgramProgress: 74,
    programId: 2,
    genreIDs: [0, 5, 6, 12, 11, 10],
    playUrl: "http://live.sportstv.com.tr/hls/low/sportstv.m3u8",
    programs: programs
  },
  {
    id: 3,
    channelName: "BBC FIRST",
    channelLogo: "http://demo.atlastv.net/posters/channels/3.png",
    currentProgramName: "Ana Haber",
    currentProgramProgress: 98,
    programId: 3,
    genreIDs: [0, 1, 2, 3, 4, 5, 6, 11],
    playUrl: "https://tv-trtturk.live.trt.com.tr/master.m3u8",
    programs: programs
  },
  {
    id: 4,
    channelName: "NAT GEO WILD",
    channelLogo: "http://demo.atlastv.net/posters/channels/4.png",
    currentProgramName: "Mars",
    currentProgramProgress: 65,
    programId: 4,
    genreIDs: [0, 1, 2, 5, 8, 9],
    playUrl: "http://live.sportstv.com.tr/hls/low/sportstv.m3u8",
    programs: programs
  },
  {
    id: 5,
    channelName: "HISTORY",
    channelLogo: "http://demo.atlastv.net/posters/channels/5.png",
    currentProgramName: "Roma Binaları",
    currentProgramProgress: 44,
    programId: 5,
    genreIDs: [0, 5, 6, 8, 12, 3],
    playUrl:
      "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8",
    programs: programs
  },
  {
    id: 6,
    channelName: "DISCOVERY",
    channelLogo: "http://demo.atlastv.net/posters/channels/6.png",
    currentProgramName: "Bilinmeyene Yolculuk",
    currentProgramProgress: 5,
    programId: 6,
    genreIDs: [0, 1, 4, 5, 9, 12],
    playUrl: "https://tv-trtturk.live.trt.com.tr/master.m3u8",
    programs: programs
  },
  {
    id: 7,
    channelName: "BBC EARTH",
    channelLogo: "http://demo.atlastv.net/posters/channels/7.png",
    currentProgramName: "Büyük Hayvan Ameliyatları",
    currentProgramProgress: 36,
    programId: 7,
    genreIDs: [0, 8, 2, 9, 6, 11],
    playUrl: "http://live.sportstv.com.tr/hls/low/sportstv.m3u8",
    programs: programs
  },
  {
    id: 8,
    channelName: "TRT 1",
    channelLogo: "http://demo.atlastv.net/posters/channels/8.png",
    currentProgramName: "Diriliş: Ertuğrul",
    currentProgramProgress: 65,
    programId: 8,
    genreIDs: [0, 1, 2, 3, 6, 8, 7],
    playUrl:
      "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8",
    programs: programs
  },
  {
    id: 9,
    channelName: "KANAL D",
    channelLogo: "http://demo.atlastv.net/posters/channels/9.png",
    currentProgramName: "Sadakatsiz",
    currentProgramProgress: 54,
    programId: 9,
    genreIDs: [0, 5, 7, 8, 9, 10, 11],
    playUrl: "http://live.sportstv.com.tr/hls/low/sportstv.m3u8",
    programs: programs
  }
];

export const channelGenres = [
  { id: 0, name: localizationStrings.allChannels },
  { id: 1, name: localizationStrings.favChannel }
  /*  { id: 2, name: "Spor" },
  { id: 3, name: "Ulusal" },
  { id: 4, name: "Sinema" },
  { id: 5, name: "Çocuk" },
  { id: 6, name: "Haber" },
  { id: 7, name: "Sinema" },
  { id: 8, name: "Müzik" },
  { id: 9, name: "Dizi" },
  { id: 10, name: "Genel" },
  { id: 11, name: "HD" },
  { id: 12, name: "Mozaik" } */
];

export const searchHistory = [
  { id: 1, title: "Asmalı Konak" },
  { id: 2, title: "Master Chef Türkiye" },
  { id: 3, title: "Over Lord" },
  { id: 4, title: "Kalk Gidelim" },
  { id: 5, title: "The Way Back" }
];

export const parentalContols = [
  {
    userlevel: 0,
    icon: <RtukGeneralIcon />,
    description:
      "Bu profilde sadece <span>GENEL İZLEYİCİ</span> yetişkinlik düzeylerindeki içerikler gösterilir."
  },
  {
    userlevel: 14,
    icon: <RtukSevenAgeIcon />,
    description:
      "Bu profilde sadece <span>üst sınırı 7+</span> olan yetişkinlik düzeyindeki içerikler gösterilir."
  },
  {
    userlevel: 12,
    icon: <RtukThirteenAgeIcon />,

    description:
      "Bu profilde sadece <span>üst sınırı 13+</span> olan yetişkinlik düzeyindeki içerikler gösterilir."
  },
  {
    userlevel: 8,
    icon: <RtukAgeIcon />,
    description:
      "Bu profilde <span>tüm yetişkinlik</span> düzeylerindeki içerikler gösterilir."
  }
];

export const languages = [
  { id: 1, lang: "tr", title: "Türkçe" },
  { id: 2, lang: "en", title: "English" }
];

export const userVersion = {
  id: 1,
  userCode: "62548796535",
  appVersion: "0.32.0"
};

export const records = [
  { id: 1, title: "Spor Gündemi", date: "01.02.2020", status: "DONE" },
  { id: 2, title: "Iron Man", date: "25.02.2020", status: "DONE" },
  { id: 3, title: "Sadakatsiz", date: "15.08.2020", status: "DONE" },
  { id: 4, title: "Maraşlı", date: "04.09.2020", status: "DONE" },
  { id: 5, title: "Masterchef", date: "26.11.2020", status: "DONE" }
];

export const purchaseHistory = [
  { id: 1, title: "Spor Gündemi", date: "01.02.2020", payment: "6.99 TL" },
  { id: 2, title: "Iron Man", date: "25.02.2020", payment: "4.99 TL" },
  { id: 3, title: "Sadakatsiz", date: "15.08.2020", payment: "9.99 TL" },
  { id: 4, title: "Maraşlı", date: "04.09.2020", payment: "6.99 TL" },
  { id: 5, title: "Masterchef", date: "26.11.2020", payment: "9.99 TL" }
];

export const userRecords = [
  { id: 1, poster: "http://demo.atlastv.net/posters/record/1.png" },
  { id: 2, poster: "http://demo.atlastv.net/posters/record/2.png" },
  { id: 3, poster: "http://demo.atlastv.net/posters/record/3.png" },
  { id: 4, poster: "http://demo.atlastv.net/posters/record/4.png" },
  { id: 5, poster: "http://demo.atlastv.net/posters/record/5.png" },
  { id: 6, poster: "http://demo.atlastv.net/posters/record/6.png" },
  { id: 7, poster: "http://demo.atlastv.net/posters/record/7.png" },
  { id: 8, poster: "http://demo.atlastv.net/posters/record/8.png" },
  { id: 9, poster: "http://demo.atlastv.net/posters/record/9.png" },
  { id: 10, poster: "http://demo.atlastv.net/posters/record/10.png" },
  { id: 11, poster: "http://demo.atlastv.net/posters/record/11.png" },
  { id: 12, poster: "http://demo.atlastv.net/posters/record/12.png" },
  { id: 13, poster: "http://demo.atlastv.net/posters/record/13.png" },
  { id: 14, poster: "http://demo.atlastv.net/posters/record/14.png" },
  { id: 15, poster: "http://demo.atlastv.net/posters/record/15.png" },
  { id: 16, poster: "http://demo.atlastv.net/posters/record/16.png" },
  { id: 17, poster: "http://demo.atlastv.net/posters/record/17.png" },
  { id: 18, poster: "http://demo.atlastv.net/posters/record/18.png" },
  { id: 19, poster: "http://demo.atlastv.net/posters/record/19.png" },
  { id: 20, poster: "http://demo.atlastv.net/posters/record/20.png" },
  { id: 21, poster: "http://demo.atlastv.net/posters/record/21.png" }
];
export const loginResponse = {
  data: {
    data: {
      returncode: 0,
      data: {}
    }
  }
};

export const profiles = {
  data: {
    returncode: 0,
    data: [
      {
        id: 1,
        name: "Alp",
        avatar: "http://demo.atlastv.net/posters/avatars/1.png"
      },
      {
        id: 2,
        name: "Sir",
        avatar: "http://demo.atlastv.net/posters/avatars/2.png"
      }
    ]
  }
};

export const normalAvatars = [
  {
    id: 1,
    poster: "bluepolarfemale"
  },
  {
    id: 2,
    poster: "blueshirtfemale"
  },
  {
    id: 3,
    poster: "blueshirtmale"
  },
  {
    id: 4,
    poster: "bunhairfemale"
  },
  {
    id: 6,
    poster: "greenshirtmale"
  },
  {
    id: 7,
    poster: "pinkhairfemale"
  },
  {
    id: 8,
    poster: "pinkshirtfemale"
  },
  {
    id: 9,
    poster: "redpolarmale"
  },
  {
    id: 10,
    poster: "redshirtmale"
  },
  {
    id: 11,
    poster: "sleevelessfemale"
  },
  {
    id: 12,
    poster: "turtleneckfemale"
  },
  {
    id: 13,
    poster: "whitehairfemale"
  },
  {
    id: 14,
    poster: "whiteshirtmale"
  },
  {
    id: 15,
    poster: "whiteturtleneckfemale"
  },
  {
    id: 16,
    poster: "yellowshirtfemale"
  },
  {
    id: 17,
    poster: "default"
  }
];

export const childAvatars = [
  {
    id: 17,
    poster: "kids1"
  },
  {
    id: 18,
    poster: "kids2"
  },
  {
    id: 19,
    poster: "kids3"
  },
  {
    id: 20,
    poster: "kids4"
  }
];

// export const avatars = [
//   {
//     id: 1,
//     poster: "icon-avatar-01.png"
//   },
//   {
//     id: 2,
//     poster: "icon-avatar-02.png"
//   },
//   {
//     id: 3,
//     poster: "icon-avatar-03.png"
//   },
//   {
//     id: 4,
//     poster: "icon-avatar-04.png"
//   },
//   {
//     id: 5,
//     poster: "icon-avatar-05.png"
//   },
//   {
//     id: 6,
//     poster: "icon-avatar-06.png"
//   },
//   {
//     id: 7,
//     poster: "icon-avatar-07.png"
//   },
//   {
//     id: 8,
//     poster: "icon-avatar-08.png"
//   },
//   {
//     id: 9,
//     poster: "icon-avatar-09.png"
//   },
//   {
//     id: 10,
//     poster: "icon-avatar-10.png"
//   },
//   {
//     id: 11,
//     poster: "icon-avatar-11.png"
//   },
//   {
//     id: 12,
//     poster: "icon-avatar-12.png"
//   },
//   {
//     id: 13,
//     poster: "icon-avatar-13.png"
//   },
//   {
//     id: 14,
//     poster: "icon-avatar-14.png"
//   },
//   {
//     id: 15,
//     poster: "icon-avatar-15.png"
//   }
// ];

export const packages = [
  {
    id: 0,
    name: "TIVIBU AILE PAKETI",
    poster: "http://demo.atlastv.net/posters/packages/1.png",
    description:
      "65’nin üzerinde HD, toplamda 110'ün üzerinde TV kanalı Ulusal kanallar, çocuk, belgesel, yaşam & eğlence, müzik kanalları 1500’ün üzerinde 'Seç İzle' içeriği seçeneğiyle.",
    payment: "6.90 TL/AY"
  },
  {
    id: 1,
    name: "TIVIBU SINEMA PAKETI",
    poster: "http://demo.atlastv.net/posters/packages/2.png",
    description:
      "85'in üzerinde HD, toplamda 125'in üzerinde TV kanalı Yerli yabancı kanallar, sinema, belgesel, çocuk, yaşam & eğlence, müzik kanallar 3500’ün üzerinde 'Seç İzle' içeriği seçeneğiyle.",
    payment: "9.90 TL/AY"
  },
  {
    id: 2,
    name: "TIVIBU SUPER PAKET",
    poster: "http://demo.atlastv.net/posters/packages/3.png",
    description:
      "90’ın üzerinde HD, toplamda 130'dan fazla TV kanalı Ulusal kanallar, çocuk, belgesel, yaşam & eğlence, müzik kanalları 3500’ün üzerinde 'Seç İzle' içeriği seçeneğiyle.",
    payment: "12.90 TL/AY"
  }
];

export const userPackage = {
  id: 0,
  name: "TIVIBU AILE PAKETI",
  poster: "http://demo.atlastv.net/posters/packages/1.png",
  description:
    "65’nin üzerinde HD, toplamda 110'ün üzerinde TV kanalı Ulusal kanallar, çocuk, belgesel, yaşam & eğlence, müzik kanalları 1500’ün üzerinde 'Seç İzle' içeriği seçeneğiyle.",
  payment: "6.90 TL/AY"
};

export const prevProgramList = [
  {
    id: 1,
    labelName: "Alaska'da Vahşi Yaşam",
    poster: "http://demo.atlastv.net/posters/programs/1.png"
  },
  {
    id: 2,
    labelName: "Uçsuz Bucaksız Kırlar",
    poster: "http://demo.atlastv.net/posters/programs/2.png"
  },
  {
    id: 3,
    labelName: "Okyanusun Derinlikleri",
    poster: "http://demo.atlastv.net/posters/programs/3.png"
  },
  {
    id: 4,
    labelName: "Madagaskar Günlüğü",
    poster: "http://demo.atlastv.net/posters/programs/4.png"
  },
  {
    id: 5,
    labelName: "Yağmur Ormanları Özel",
    poster: "http://demo.atlastv.net/posters/programs/5.png"
  },
  {
    id: 6,
    labelName: "Alaska'da Vahşi Yaşam",
    poster: "http://demo.atlastv.net/posters/programs/1.png"
  },
  {
    id: 7,
    labelName: "Uçsuz Bucaksız Kırlar",
    poster: "http://demo.atlastv.net/posters/programs/2.png"
  },
  {
    id: 8,
    labelName: "Okyanusun Derinlikleri",
    poster: "http://demo.atlastv.net/posters/programs/3.png"
  },
  {
    id: 9,
    labelName: "Madagaskar Günlüğü",
    poster: "http://demo.atlastv.net/posters/programs/4.png"
  },
  {
    id: 10,
    labelName: "Yağmur Ormanları Özel",
    poster: "http://demo.atlastv.net/posters/programs/5.png"
  },
  {
    id: 11,
    labelName: "Alaska'da Vahşi Yaşam",
    poster: "http://demo.atlastv.net/posters/programs/1.png"
  },
  {
    id: 12,
    labelName: "Uçsuz Bucaksız Kırlar",
    poster: "http://demo.atlastv.net/posters/programs/2.png"
  },
  {
    id: 13,
    labelName: "Okyanusun Derinlikleri",
    poster: "http://demo.atlastv.net/posters/programs/3.png"
  },
  {
    id: 14,
    labelName: "Madagaskar Günlüğü",
    poster: "http://demo.atlastv.net/posters/programs/4.png"
  },
  {
    id: 15,
    labelName: "Yağmur Ormanları Özel",
    poster: "http://demo.atlastv.net/posters/programs/5.png"
  },
  {
    id: 16,
    labelName: "Alaska'da Vahşi Yaşam",
    poster: "http://demo.atlastv.net/posters/programs/1.png"
  },
  {
    id: 17,
    labelName: "Uçsuz Bucaksız Kırlar",
    poster: "http://demo.atlastv.net/posters/programs/2.png"
  },
  {
    id: 18,
    labelName: "Okyanusun Derinlikleri",
    poster: "http://demo.atlastv.net/posters/programs/3.png"
  },
  {
    id: 19,
    labelName: "Madagaskar Günlüğü",
    poster: "http://demo.atlastv.net/posters/programs/4.png"
  },
  {
    id: 20,
    labelName: "Yağmur Ormanları Özel",
    poster: "http://demo.atlastv.net/posters/programs/5.png"
  }
];
