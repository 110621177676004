import React, { useRef } from "react";

const WireCard = ({ url, closeFrame }) => {
  const iframeRef = useRef();
  return (
    <div className="wirecard-container">
      <div className="close-button black" onClick={() => closeFrame()}></div>
      <iframe
        ref={iframeRef}
        className="wirecard-frame"
        onLoad={(e) => {
          if (
            iframeRef.current.contentWindow.location.href.includes("filbox.com")
          ) {
            closeFrame();
          }
        }}
        src={url}
      ></iframe>
    </div>
  );
};

export default WireCard;
