import React from "react";
import { useHistory } from "react-router-dom";

import "./Packets.scss";
import LoginWrapper from "../loginWrapper";
import packetImage from "../../assets/images/multi-screen.png";
import ArrowRightPurple from "../../assets/icons/svg/ArrowRightPurple";

const Packets = () => {
  const history = useHistory();
  return (
    <LoginWrapper>
      <p className="login-title" style={{ color: "white", fontWeight: "800" }}>
        Hangi Filbox size göre?
      </p>
      <p
        className="article2"
        style={{
          fontSize: "18px",
          color: "white",
          fontWeight: "400",
          opacity: ".75",
          marginTop: "2rem"
        }}
      >
        Filbox’nun renkli dünyası ile tanışmaya hazır mısın?
      </p>
      <div className="packet">
        <img src={packetImage} alt="packet-image" />
        <p className="packet-title">Spor Paketi</p>
        <p className="packet-description">
          Filbox'a abone olarak FiLSPOR kanallarını izleyebilir, ulusal
          kanallarda en sevdiğin dizileri takip edebilirsin.
        </p>
        <button onClick={() => history.push("/purchase")}>Spor Paketi</button>
      </div>
      <button
        onClick={() => {
          history.push("/signup");
        }}
        className="back-btn"
      >
        <ArrowRightPurple /> Geri
      </button>
    </LoginWrapper>
  );
};

export default Packets;
