import axios from "axios";
import * as mock from "../mockup/mockup";
import {
  browserName,
  fullBrowserVersion,
  isEdge,
  isOpera,
  isSafari,
  isFirefox,
  isChrome
} from "react-device-detect";
import { sendCustomProfileReport } from "../../utils/reporting/customReporting";
import { config } from "winston";
import { apiEndPoint, fastLoginSessionKillUrl } from "../../statics/config";
import { apiEndPointConstant } from "../../statics/constants";
import { axiosInstance } from "../config/configApi";

let terminalOsType = null;
switch (true) {
  case isChrome:
    terminalOsType = 9;
    break;
  case isSafari:
    terminalOsType = 10;
    break;
  case isEdge:
    terminalOsType = 11;
    break;
  case isFirefox:
    terminalOsType = 12;
    break;
  case isOpera:
    terminalOsType = 13;
    break;
  default:
    terminalOsType = 0;

    break;
}

const getProfilesApi = async () => {
  return await axiosInstance.get(apiEndPointConstant.authClient + "/profiles");
};

const addProfile = async (name, isKid, pin, posterName) => {
  return await axios.post(apiEndPointConstant.authClient + "/profiles", {
    name: name,
    kid: isKid,
    pin: pin,
    posterName: posterName
  });
};

const deleteProfile = async (id) => {
  return await axios.delete(apiEndPointConstant.authClient + "/profiles/" + id);
};

const updateProfile = async (id, name, pin, posterName, isKid) => {
  return await axios.put(apiEndPointConstant.authClient + "/profiles", {
    id: id,
    name: name,
    pin: pin,
    posterName: posterName,
    kid: isKid
  });
};

const loginWithProfile = async (id, pin) => {
  return await axiosInstance.post(
    apiEndPointConstant.authClient + "/profiles/select",
    {
      id: id,
      pin: pin
    }
  );
};

const checkProfilePin = async (id, pin) => {
  return await axiosInstance.post(
    apiEndPointConstant.authClient + "/profiles/validate/pin/" + id,
    pin,
    {
      headers: {
        "Content-Type": "text/plain"
      }
    }
  );
};

const modifyProfile = async (
  profileCode,
  userLevel,
  nickName,
  photo,
  password,
  profileRoleId,
  isRemovePassword
) => {
  return await axios.post(apiEndPointConstant.authClient + "/");
};

const setUserMediaServices = async (apiEndpoint, type, sessiondId) => {
  return await axios.post(
    `${apiEndpoint}/sdk_setusermediaservices.jsp`,
    `mediaservices=${type}`
  );
};

const setUserLanguage = async (apiEndpoint, sessiondId, language) => {
  if (!apiEndpoint || !sessiondId) {
    apiEndpoint = localStorage.getItem("interfaceurl");
    sessiondId = localStorage.getItem("sessionid");
  }
  return await axios.post(
    `${apiEndpoint}/sdk_setuserlanguage.jsp`,
    `languagetype=${language}`
  );
};

const setPlayInitialInfo = async () => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.post(
    `${apiEndpoint}/sdk_getplayinitialinfo.jsp`,
    `ostype=${browserName}&osversion=${fullBrowserVersion}`
  );
};

const changeProfileParentalLevel = async (profileCode, userLevel) => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");

  return await axios.post(
    `${apiUrl}/sdk_modifyprofile.jsp`,
    `profilecode=${profileCode}&limitlevel=${userLevel}`
  );
};

const getProfileInfo = async (profileCode) => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");

  return await axios.get(`${apiUrl}/sdk_queryprofile.jsp`, {
    params: {
      profilecode: profileCode
    }
  });
};

const checkProfilePassword = async (profileCode, pinCode) => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");

  return await axios.post(
    `${apiUrl}/sdk_checkpwdprofile.jsp`,
    `profilecode=${encodeURIComponent(profileCode)}&pwd=${encodeURIComponent(
      pinCode
    )}`
  );
};

const doPaymentTvod = async (
  programType,
  programCode,
  productCode,
  paymentType,
  deviceid
) => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");

  return await axios.get(`${apiUrl}/sdk_getmpay.jsp`, {
    params: {
      programtype: programType,
      paytype: paymentType,
      productcode: productCode,
      terminaltype: 4,
      programcode: programCode,
      deviceid: deviceid
    }
  });
};

const payWithUserPromoCode = async (
  programType,
  programCode,
  productCode,
  promoCode,
  deviceid
) => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");

  return await axios.get(`${apiUrl}/sdk_getmpay.jsp`, {
    params: {
      programtype: programType,
      paytype: 23,
      productcode: productCode,
      terminaltype: 4,
      programcode: programCode,
      voucherid: promoCode,
      deviceid: deviceid
    }
  });
};

const getWirecardPayResult = async (mPayGuid) => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");

  return await axios.get(`${apiUrl}/sdk_getpayresult.jsp`, {
    params: {
      mpay: mPayGuid
    }
  });
};

const doAuth = async (programCode, columnCode, contentType) => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");

  return await axios.post(
    `${apiUrl}/doauth.jsp`,
    `programcode=${programCode}&columncode=${columnCode}&contenttype=${contentType}`
  );
};

const doUnsubscribe = async (mPay) => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");

  return await axios.get(`${apiUrl}/sdk_unsubscribe.jsp`, {
    params: {
      mpay: mPay
      // purchasetype: 3,
      // productcode: productCode,
      // contenttype: 1,
      // contentcode: contentCode,
      // action: 4,
      // columncode: columnCode
    }
  });
};

const getUserInitialInfo = async () => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");
  return await axios.get(
    `${apiUrl}/sdk_getinitialuserinfo.jsp${sessionID ? `` : ""}`
  );
};

const getProfileInitialData = async () => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");
  return await axios.get(`${apiUrl}/sdk_getprofileinitdata.jsp`);
};

const getUserPurchaseHistory = async () => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");
  return await axios.get(`${apiUrl}/sdk_getorderandconsumelist.jsp`, {
    params: {
      pageno: 1,
      numperpage: 500,
      producttype: "2,3,5,7"
    }
  });
};

const callLogout = async (usercode) => {
  var sessionID = localStorage.getItem("sessionid");
  var apiUrl = localStorage.getItem("interfaceurl");
  apiUrl = apiUrl.slice(0, apiUrl.lastIndexOf("/"));
  let userCode = window.EncryptUtils.dencryptStr(decodeURIComponent(usercode));
  return await axios.get(`${apiUrl}/datasource/mobilelogin.jsp`, {
    params: {
      UserID: userCode,
      Action: "Logout",
      TerminalFlag: 4,
      TerminalOsType: terminalOsType
    }
  });
};

const callMobileConnectLogoutApi = async () => {
  let testUrl =
    "https://test-kolaygiris.turktelekom.com.tr/kolaygiris/mcLogout?client_id=tivibuweb";
  return await axios.get(`${testUrl}/kolaygiris/mcLogout`, {
    params: { client_id: "tivibuweb" },
    headers: { "Content-Type": "text/plain" }
  });
};

export {
  setUserMediaServices,
  setUserLanguage,
  payWithUserPromoCode,
  getProfilesApi,
  loginWithProfile,
  checkProfilePin,
  checkProfilePassword,
  addProfile,
  doPaymentTvod,
  setPlayInitialInfo,
  deleteProfile,
  updateProfile,
  getProfileInfo,
  getWirecardPayResult,
  getUserInitialInfo,
  doAuth,
  doUnsubscribe,
  callLogout,
  modifyProfile,
  getUserPurchaseHistory,
  getProfileInitialData,
  changeProfileParentalLevel,
  callMobileConnectLogoutApi
};
