import React from "react";

const ArrowRightBlack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1vw"
      height="1.675vw"
      viewBox="0 0 20 37.5"
    >
      <g
        id="Actions_Navigation_arrow--left_24"
        data-name="Actions / Navigation / arrow--left / 24"
        transform="translate(0 -18)"
      >
        <path
          id="Fill"
          d="M20,18.75,2.456,37.5,0,34.875,15.088,18.75,0,2.625,2.456,0Z"
          transform="translate(0 18)"
          fill="#1B1629"
        />
      </g>
    </svg>
  );
};

export default ArrowRightBlack;
